// 
// dropdowns.scss
// Extended from Bootstrap
// 

// 
// Bootstrap Overrides
//
.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
}

.dropdown-item {
  font-weight: $dropdown-item-font-weight;
  text-transform: capitalize;
  position: relative;
}

.dropdown-item.active {
  color: var(--#{$prefix}primary);
  &:before {
    content: "";
    width: 7px;
    height: 7px;
    background:var(--#{$prefix}primary);
    display: inline-block;
    border-radius: 50%;
    margin-left: -12px;
    margin-right: 5px;
    margin-bottom: 1px;
  }
}

// Dropdown arrows
// Replace original arrows with Custom icons
// 
.dropdown-toggle {
  &:after {
    content: "\f078";
    font-family: $fa-font-family;
    font-size: 0.5rem;
    margin-left: 0.35rem;
    font-weight: 900;
    vertical-align: middle;
    border: none;
  }
}

.dropup .dropdown-toggle {
  &:after {
    content: "\f077";
    font-family: $fa-font-family;
    font-size: 0.5rem;
    margin-left: 0.35rem;
    font-weight: 900;
    vertical-align: middle;
    border: none;
  }
}

// Dropdown overrides for navbar only
.dropdown-menu .dropdown-toggle {
  &:after {
    position: absolute;
    right: 25px;
    top: 10px
  }
  &:before {
    position: absolute;
    right: 25px;
    top: 10px
  }
}


// 
// Additional style for theme
// 

// Dropdown mega menu
.dropdown-fullwidth {
  position: static;
  .dropdown-menu {
    margin: 0 auto;
    left: 0;
    right: 0;
    padding: 1rem 0;
  }
}

// Dropdown fullwidth menu width in breakpoint up
@each $breakpoint, $container-max-width in $container-max-widths {
  @include media-breakpoint-up($breakpoint) {
    .dropdown-fullwidth {
      .dropdown-menu {
        max-width: $container-max-width; 
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .dropdown-fullwidth .dropdown-menu .container{
    max-width: 100%;
  }
}

// Dropdown menu open on hover after xl
@include media-breakpoint-up(xl) {
  .navbar-expand-xl {
    .navbar-nav {
      .dropdown-menu {
        top: 120%;
        visibility: hidden;
        opacity: 0;
        display: block;
        transition: all 0.1s ease-in-out;
      }
      .dropdown {
        &:hover {
          >.dropdown-menu {
            top: 100%;
            visibility: visible;
            opacity: 1;
            transition: $transition-base;
          }
        }
      }
      .dropdown-submenu {
        &:hover {
          >.dropdown-menu {
            top: 0;
            visibility: visible;
            opacity: 1;
            transition: $transition-base;
          }
        }
      }
    }
  }
  .navbar-expand-xl .dropdown-submenu {
    position: relative;
  }
}

// Dropdown menu open on hover after lg
@include media-breakpoint-up(lg) {
  .navbar-expand-lg {
    .navbar-nav {
      .dropdown-menu {
        top: 120%;
        visibility: hidden;
        opacity: 0;
        display: block;
        transition: all 0.1s ease-in-out;
      }
      .dropdown {
        &:hover {
          >.dropdown-menu {
            top: 100%;
            visibility: visible;
            opacity: 1;
            transition: $transition-base;
          }
        }
      }
      .dropdown-submenu {
        &:hover {
          >.dropdown-menu {
            top: 0;
            visibility: visible;
            opacity: 1;
            transition: $transition-base;
          }
        }
      }
    }
  }
  .navbar-expand-lg .dropdown-submenu {
    position: relative;
  }
}


// Dropdown menu open on hover after md
@include media-breakpoint-up(md) {
  .navbar-expand-md {
    .navbar-nav {
      .dropdown-menu {
        top: 120%;
        visibility: hidden;
        opacity: 0;
        display: block;
        transition: all 0.1s ease-in-out;
      }
      .dropdown {
        &:hover {
          >.dropdown-menu {
            top: 100%;
            visibility: visible;
            opacity: 1;
            transition: $transition-base;
          }
        }
      }
      .dropdown-submenu {
        &:hover {
          >.dropdown-menu {
            top: 0;
            visibility: visible;
            opacity: 1;
            transition: $transition-base;
          }
        }
      }
    }
  }
  .navbar-expand-md .dropdown-submenu {
    position: relative;
  }
}

// Dropdown menu open on hover after sm
@include media-breakpoint-up(sm) {
  .navbar-expand-sm {
    .navbar-nav {
      .dropdown-menu {
        top: 120%;
        visibility: hidden;
        opacity: 0;
        display: block;
        transition: all 0.1s ease-in-out;
      }
      .dropdown {
        &:hover {
          >.dropdown-menu {
            top: 100%;
            visibility: visible;
            opacity: 1;
            transition: $transition-base;
          }
        }
      }
      .dropdown-submenu {
        &:hover {
          >.dropdown-menu {
            top: 0;
            visibility: visible;
            opacity: 1;
            transition: $transition-base;
          }
        }
      }
    }
  }
  .navbar-expand-sm .dropdown-submenu {
    position: relative;
  }
}

// dropdown menu md size
.dropdown-menu-size-md{
  min-width: 20rem;
}
@include media-breakpoint-down(sm) {
  .dropdown-menu-size-md{
    min-width: 15rem;
  }
}

// dropdown toggle icon none
.dropdown-toggle-icon-none{
  .dropdown-toggle:after{
    content: none;
  }
}